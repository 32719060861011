import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Presenta un capell carnós, llis, lluent i viscós de 3 a 8 cm de diàmetre amb forma de campana amb un mamelló central. Color roig terrós amb reflexos color safrà cap al marge que és fi i un poc corbat interiorment. Davall el capell presenta unes làmines decurrents i espaiades, gruixades i blanes de color marró porpres quasi negres. Presenta un peu cilíndric, llarg i prim en comparació amb el capell, de 3 a 8 cm normalment per 5 a 10 mm de diàmetre un poc corbat a la base, de color groc. Les espores són de color terrós fosc o quasi negres, de 16-22 x 6-8 micres.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      